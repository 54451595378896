// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { StatusMessage } from './status-message';
import api from './api';
import prepareUrl from './prepareUrl';
import render from './render';
import createElement from './createElement';

export class UpgradeFailureNotification extends StatusMessage {
    _initConfiguration(config) {
        this.updateInterval = 5000;

        super._initConfiguration(config);
        this._isBootstrapRunning = this._getConfigParam('isBootstrapRunning');
        this._bootstrapExecutionUrl = this._getConfigParam('bootstrapExecutionUrl');
        this._bootstrapStatusUrl = this._getConfigParam('bootstrapStatusUrl');
        this._bootstrapInProgressMessage = this._getConfigParam('bootstrapInProgressMessage');
        this._bootstrapLinkTitle = this._getConfigParam('bootstrapLinkTitle');
    }

    _addEvents() {
        super._addEvents();
        if (this._isBootstrapRunning) {
            this._scheduleUpdateStatus();
        }
    }

    _executeBootstrapper() {
        this._isBootstrapRunning = true;
        render(this._componentElement.querySelector('.msg-content'), this._renderMessage(), 'inner');
        api.post(prepareUrl(this._bootstrapExecutionUrl))
            .finally(() => {
                this._scheduleUpdateStatus();
            });
    }

    _renderMessage() {
        if (this._isBootstrapRunning) {
            return createElement('span.ajax-loading', this._bootstrapInProgressMessage);
        }
        return [this._message, ' ', createElement('a', { onclick: this._executeBootstrapper.bind(this) }, this._bootstrapLinkTitle)];
    }

    _scheduleUpdateStatus() {
        setTimeout(() => {
            this._statusUpdate();
        }, this.updateInterval);
    }

    _statusUpdate() {
        api.get(prepareUrl(this._bootstrapStatusUrl))
            .finally(() => {
                this._scheduleUpdateStatus();
            });
    }
}
