// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { Component } from './component';
import prepareUrl from './prepareUrl';
import escapeHtml from './escapeHtml';

/**
 * Example usage:
 *
 *     @example
 *     new Button({
 *         renderTo: document.body,
 *         title: 'Add a Customer',
 *         cls: 's-btn sb-add-new-customer',
 *         isNew: true,
 *         newTitle: 'New',
 *         handler() {
 *             alert('You clicked the button!');
 *         },
 *     });
 */
export class Button extends Component {
    /**
     * @cfg {String} title=""
     */
    /**
     * @cfg {String} rightIcon=""
     */
    /**
     * @cfg {String|Function} handler
     */
    /**
     * @cfg {String} href
     */
    /**
     * @cfg {String} target
     */
    /**
     * @cfg {String} disabledClass=btn-disabled
     */
    /**
     * @cfg {Boolean} isNew=false
     */
    /**
     * @cfg {String} newTitle=""
     */

    _initConfiguration(config) {
        super._initConfiguration({ tag: 'a', ...config });

        this._handler = this._getConfigParam('handler', false);
        if (typeof this._handler === 'string') {
            // eslint-disable-next-line no-eval
            eval(`this._handler = ${this._handler}`);
        }
        this._title = this._getConfigParam('title', '');
        this._rightIcon = this._getConfigParam('rightIcon', '');
        this._cls = this._getConfigParam('cls', `b-btn${!this._title ? ' btn-icon-only' : ''}`);
        this._disabledClass = this._getConfigParam('disabledClass', 'btn-disabled');
        this._isNew = this._getConfigParam('isNew', false);
        this._newTitle = this._getConfigParam('newTitle', '');
    }

    _initComponentElement() {
        super._initComponentElement();

        this._componentElement.innerHTML = (
            '<i><i><i>' +
                `<span>${escapeHtml(this._title)}${this._isNew ? ` <span class="badge-new">${this._newTitle}</span>` : ''}${this._rightIcon}</span>` +
            '</i></i></i>'
        );

        this._initVisibility();
    }

    _initVisibility() {
        this._disabled = this._getConfigParam('disabled', false);

        if (this._disabled) {
            this._disabled = false;
            this.disable();
        } else {
            this._disabled = true;
            this.enable();
        }
    }

    _addAttribute(name, value) {
        if ('undefined' !== typeof value && value !== null) {
            this._componentElement.setAttribute(name, value);
        }
    }

    enable() {
        if (!this._disabled) {
            return;
        }
        this._disabled = false;

        let classNames = this._config.addCls || '';
        if (typeof classNames === 'string') {
            classNames = classNames.split(' ').filter(className => className);
        } else {
            classNames = [classNames];
        }
        classNames.each(item => {
            this._componentElement.classList.add(item);
            this._componentElement.classList.remove(`${item}-disabled`);
        });
        this._componentElement.classList.remove(this._disabledClass);

        this._addAttribute('href', prepareUrl(this._getConfigParam('href', 'javascript:;')));
        this._addAttribute('target', this._getConfigParam('target', null));

        this._addProgressHandler();
        if (this._handler) {
            this.addEventObserver('click', this._handler);
        }
    }

    disable() {
        if (this._disabled) {
            return;
        }
        this._disabled = true;

        this._componentElement.classList.add(this._disabledClass);

        let classNames = this._config.addCls || '';
        if (typeof classNames === 'string') {
            classNames = classNames.split(' ').filter(className => className);
        } else {
            classNames = [classNames];
        }
        classNames.each(item => {
            this._componentElement.classList.add(`${item}-disabled`);
            this._componentElement.classList.remove(item);
        });
        this._componentElement.removeAttribute('href');
        this._componentElement.removeAttribute('target');

        if (this._handler) {
            this.removeEventObserver('click', this._handler);
        }
    }

    _addProgressHandler() {
        // basic empty implementation
    }
}
