// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { Form, FormFieldPassword, Translate } from '@plesk/ui-library';
import { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { redirect } from 'jsw';
import { withLoginLayout } from './LoginLayout';

class RestorePassword extends Component {
    state = {
        values: this.props.values,
        errors: this.props.errors,
    };

    componentDidMount() {
        if (document.querySelector('input[name="password"]')) {
            document.querySelector('input[name="password"]').focus();
        }
        if (document.getElementById('forgery_protection_token')) {
            this.forgeryProtectionToken = document.getElementById('forgery_protection_token').content; // eslint-disable-line camelcase
        }
    }

    handleSubmit = values => {
        const errors = {};
        if (!values.password) {
            errors.password = {
                isEmpty: <Translate content="isEmpty" />,
            };
            document.querySelector('input[name="password"]').focus();
        }

        this.setState({ errors, values });

        if (Object.keys(errors).length) {
            return;
        }

        const form = findDOMNode(this);
        form.submit();
    };

    render() {
        const { errors, values } = this.state;

        return (
            <Form
                id="form-restore-password"
                autocomplete="off"
                method="post"
                onSubmit={this.handleSubmit}
                submitButton={{
                    children: <Translate content="buttonRestore" />,
                }}
                applyButton={false}
                cancelButton={{
                    children: <Translate content="buttonCancel" />,
                    onClick: () => {
                        redirect('/login_up.php');
                    },
                }}
                values={values}
                errors={errors}
                hideRequiredLegend
                vertical
            >
                <h3><Translate content="title" /></h3>
                <FormFieldPassword
                    title={<Translate content="passwordLabel" />}
                    name="password"
                    size="fill"
                    passwordMeterProps={{ placement: 'top-right' }}
                />
                {this.forgeryProtectionToken && (
                    <input type="hidden" name="forgery_protection_token" defaultValue={this.forgeryProtectionToken} />
                )}
            </Form>
        );
    }
}

RestorePassword.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default withLoginLayout(RestorePassword);
