// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import escapeHtml from './escapeHtml';

/**
 * Escape element attributes for html concatenation
 *
 * @param {String} value
 * @returns {String}
 */
export default value => escapeHtml(value)
    .replace(/"/g, '&quot;');

