// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import PropTypes from 'prop-types';
import { Translate, Form, FormFieldText, FormFieldRadioButtons, Heading } from '@plesk/ui-library';
import { redirect } from 'jsw';
import { withLoginLayout } from './LoginLayout';

const GetPassword = ({ values }) => {
    const handleSubmit = () => {
        document.getElementById('form-changepassword-getlink').submit();
    };

    const handleCancelClick = () => {
        redirect('/login_up.php');
    };

    return (
        <>
            <Heading level="3"><Translate content="label" /></Heading>
            <p><Translate content="text" /></p>
            <Form
                id="form-changepassword-getlink"
                method="post"
                onSubmit={handleSubmit}
                values={{
                    resetBy: 'email',
                    ...values,
                }}
                applyButton={false}
                submitButton={{
                    children: <Translate content="send" />,
                    type: 'submit',
                    'data-action': 'send',
                }}
                cancelButton={{
                    onClick: handleCancelClick,
                    children: <Translate content="cancel" />,
                    'data-action': 'cancel',
                }}
                vertical
            >
                <FormFieldRadioButtons
                    name="resetBy"
                    options={[
                        {
                            value: 'email',
                            label: <Translate content="emailLabel" />,
                            indentFields: [
                                <FormFieldText
                                    key="reset-by-email"
                                    name="email"
                                    autoFocus
                                    size="fill"
                                />,
                            ],
                        },
                        {
                            value: 'login',
                            label: <Translate content="loginLabel" />,
                            indentFields: [
                                <FormFieldText
                                    key="reset-by-login"
                                    name="login"
                                    size="fill"
                                />,
                            ],
                        },
                    ]}
                />
                {document.getElementById('forgery_protection_token') ? (
                    <input
                        type="hidden"
                        name="forgery_protection_token"
                        value={document.getElementById('forgery_protection_token').content}
                    />
                ) : null}
            </Form>
        </>
    );
};

GetPassword.propTypes = {
    values: PropTypes.shape({
        email: PropTypes.string,
        login: PropTypes.string,
    }).isRequired,
};

export default withLoginLayout(GetPassword);
