// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import addStatusMessage from './addStatusMessage';
import clearStatusMessages from './clearStatusMessages';
import escapeHtml from './escapeHtml';

export default message => {
    clearStatusMessages();
    let statusMessage = `Internal error<pre>${escapeHtml(message)}</pre>`;
    try {
        const jsonMessage = JSON.parse(message);
        if ('message' in jsonMessage && 'kbArticleUrl' in jsonMessage) {
            statusMessage = `<pre>${escapeHtml(jsonMessage.message)}</pre>`;
            statusMessage += `<a href="${jsonMessage.kbArticleUrl}" target="_blank">Search for related Knowledge Base articles</a>`;
        }
    } catch {
        // Show as plaintext
    }

    addStatusMessage('error', statusMessage);
};
