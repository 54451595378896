// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import escapeHtml from './escapeHtml';

import './tooltip.less';

/**
 * @class Tooltip
 * @singleton
 */
export const Tooltip = {
    // show timeout
    showTimeoutStatus: true,

    // show timeout
    showTimeout: 0.55,

    // minimum viewport delta
    minDelta: 5,

    // horisonatl offset from cursor
    xOffsetLeft: -5,

    // horisonatl offset from cursor
    xOffsetRight: 5,

    // vertical offset from cursor
    yOffsetTop: 1,

    // vertical offset from cursor
    yOffsetBottom: 13,

    current: null,

    initData(contentElement) {
        let elements;

        if ('undefined' !== typeof contentElement) {
            elements = contentElement.querySelectorAll('.tooltipData');
        } else {
            elements = document.querySelectorAll('.tooltipData');
        }

        elements.forEach(tooltipData => {
            Tooltip.init(tooltipData.parentNode, { element: tooltipData });
        });
    },

    /**
     * @param {Element} element
     * @param {Object} config Tooltip config.
     * @param {String} config.text Text of tooltip.
     * @returns {Tooltip.Instance}
     */
    init(element, config) {
        return new Tooltip.Instance(element, config);
    },

    set(tooltip) {
        if (Tooltip.current && Tooltip.current !== tooltip) {
            Tooltip.hide();
        }
        if (Tooltip.current === tooltip) {
            return;
        }
        Tooltip.current = tooltip;
        tooltip.element.addEventListener('mousemove', Tooltip._onMouseMove);
        tooltip.element.addEventListener('mouseout', Tooltip._onMouseOut);

        Tooltip.show();
    },

    show() {
        Tooltip.showCancel();
        Tooltip.hideCancel();
        if (Tooltip.showTimeoutStatus) {
            Tooltip.showTask = setTimeout(Tooltip.showFinish, Tooltip.showTimeout * 1000);
        } else {
            Tooltip.showFinish();
        }
    },

    showCancel() {
        if (Tooltip.showTask) {
            clearTimeout(Tooltip.showTask);
            Tooltip.showTask = null;
        }
    },

    showFinish() {
        Tooltip.showCancel();
        const tooltipElement = Tooltip._fetchTooltipElement();
        const tooltip = Tooltip.current;
        let { text } = tooltip.config;
        text = typeof text === 'function' ? text() : text;

        if (text === false) {
            return;
        }

        if (text) {
            tooltipElement.querySelector('span').innerHTML = escapeHtml(text);
        } else if (tooltip.config.element) {
            tooltipElement.querySelector('span').innerHTML = tooltip.config.element.innerHTML;
        }
        tooltipElement.style.display = '';
        Tooltip.update();
    },

    hide() {
        Tooltip.showCancel();
        if (!Tooltip.current) {
            return;
        }
        const tooltip = Tooltip.current;
        Tooltip.current = null;
        const tooltipElement = Tooltip._fetchTooltipElement();
        tooltipElement.style.display = 'none';
        tooltip.element.removeEventListener('mousemove', Tooltip._onMouseMove);
        tooltip.element.removeEventListener('mouseout', Tooltip._onMouseOut);
        Tooltip.hideTask = setTimeout(Tooltip.hideFinish, Tooltip.showTimeout * 1000);
    },

    hideCancel() {
        if (Tooltip.hideTask) {
            clearTimeout(Tooltip.hideTask);
            Tooltip.hideTask = null;
        }
    },

    hideFinish() {
        Tooltip.showTimeoutStatus = true;
    },

    update() {
        if (!Tooltip.current) {
            return;
        }
        const tooltipElement = Tooltip._fetchTooltipElement();
        if (tooltipElement.style.display === 'none') {
            return;
        }
        const position = Tooltip._fetchTooltipPosition(tooltipElement);
        tooltipElement.style.top = `${position.y}px`;
        tooltipElement.style.left = `${position.x}px`;
    },

    _onMouseOut() {
        Tooltip.hide();
    },

    _onMouseMove(event) {
        Tooltip.tooltipX = event.clientX + (window.scrollX || window.pageXOffset);
        Tooltip.tooltipY = event.clientY + (window.scrollY || window.pageYOffset);
        Tooltip.update();
    },

    _fetchTooltipElement() {
        if (!Tooltip._tooltipElement) {
            const el = document.createElement('div');
            el.className = 'tooltip';
            el.innerHTML = '<b><i><i><i><span></span></i></i></i></b>';
            el.style.display = 'none';
            document.body.appendChild(el);
            Tooltip._tooltipElement = el;
        }

        return Tooltip._tooltipElement;
    },

    _fetchTooltipPosition(tooltipElement) {
        let x;
        let y;

        const tDim = Element.getDimensions(tooltipElement);
        const vDim = document.viewport.getDimensions();
        const vScr = document.viewport.getScrollOffsets();

        const freeRight = vDim.width + vScr.left - Tooltip.tooltipX;
        const freeLeft = Tooltip.tooltipX - vScr.left;
        const freeTop = Tooltip.tooltipY - vScr.top;
        const freeBottom = vDim.height + vScr.top - Tooltip.tooltipY;

        // apply better x
        if (freeRight > tDim.width + Tooltip.minDelta + Tooltip.xOffsetRight) {
            x = Tooltip.tooltipX + Tooltip.xOffsetRight;
        } else if (freeLeft > tDim.width + Tooltip.minDelta + Tooltip.xOffsetLeft) {
            x = Tooltip.tooltipX - tDim.width - Tooltip.xOffsetLeft;
        } else {
            x = Tooltip.tooltipX - ((tDim.width + Tooltip.xOffsetLeft) / 2);
        }

        // apply better y
        if (freeBottom > tDim.height + Tooltip.minDelta + Tooltip.yOffsetBottom) {
            y = Tooltip.tooltipY + Tooltip.yOffsetBottom;
        } else if (freeTop > tDim.height + Tooltip.minDelta + Tooltip.yOffsetTop) {
            y = Tooltip.tooltipY - tDim.height - Tooltip.yOffsetTop;
        } else {
            y = Tooltip.tooltipY - ((tDim.height + Tooltip.yOffsetTop) / 2);
        }
        if (x < 0) {
            x = Tooltip.minDelta;
        }

        return { x, y };
    },
};

window.addEventListener('load', () => {
    Tooltip.initData();
});

/**
 * @class Tooltip.Instance
 */
Tooltip.Instance = class {
    constructor(element, config) {
        this.element = typeof element === 'string' ? document.getElementById(element) : element;
        this.config = config;

        // observe events
        this.element.removeEventListener('mouseover', this.set);
        this.element.addEventListener('mouseover', this.set);
    }

    set = () => {
        Tooltip.set(this);
    };

    setText(text) {
        this.config.text = escapeHtml(text);
    }
};
