// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { render as renderReact } from 'react-dom';
import { isValidElement } from 'react';
import { Component } from './component';
import emptyFn from './emptyFn';

const addScript = src => new Promise((resolve, reject) => {
    const s = document.createElement('script');
    s.setAttribute('src', src);
    s.onload = resolve;
    s.onerror = reject;
    document.body.appendChild(s);
});

/**
 * Helper for render components/elements/strings
 * @experimental Be careful with this method, its implementation is not yet complete.
 *
 * @param {Element} parentElement
 * @param {Component|Function|Element|Object|String} element
 * @param {String} [renderMode=bottom"] above|below|top|bottom|inner|replace
 */
const render = (parentElement, element, renderMode = 'bottom') => {
    if (!element) {
        return;
    }

    if (renderMode === 'inner') {
        parentElement.innerHTML = '';
        renderMode = 'bottom';
    }

    if (Array.isArray(element)) {
        element.forEach(element => {
            render(parentElement, element, renderMode);
        });
        return;
    }

    if (typeof element === 'function') {
        render(parentElement, element(), renderMode);
        return;
    }

    if (element instanceof Component) {
        element.setRenderTarget(parentElement);
        if (renderMode) {
            element._renderMode = renderMode;
        }
        element.render();
        return;
    }

    if (isValidElement(element)) {
        renderReact(element, parentElement);
        return;
    }

    if (Object.prototype.toString.call(element) === '[object Object]' && element.tag) {
        const attrs = { ...element.attrs };
        Object.keys(attrs).forEach(function (attr) {
            if ('undefined' === typeof attrs[attr]) {
                delete attrs[attr];
            }
        });

        const renderFn = typeof attrs.onrender === 'function' ? attrs.onrender : emptyFn;
        delete attrs.onrender;

        const events = {};
        Object.keys(attrs).forEach(attr => {
            if (typeof attrs[attr] === 'function' && attr.indexOf('on') === 0) {
                events[attr.slice(2)] = attrs[attr];
                delete attrs[attr];
            }
        });

        const el = document.createElement(element.tag);
        Object.keys(attrs).forEach(name => {
            if (attrs[name] === true) {
                el.setAttribute(name, name);
            } else if (attrs[name] !== null && attrs[name] !== false) {
                el.setAttribute(name, attrs[name]);
            }
        });
        Object.keys(events).forEach(eventName => {
            el.addEventListener(eventName, events[eventName]);
        });

        render(el, element.children);

        element = el;
        renderFn(element);
    }

    switch (renderMode) {
        case 'bottom':
            if (element instanceof Node) {
                parentElement.insertBefore(element, null);
            } else {
                parentElement.insertAdjacentHTML('beforeend', element);
            }
            break;

        case 'top':
            if (element instanceof Node) {
                parentElement.insertBefore(element, parentElement.firstChild);
            } else {
                parentElement.insertAdjacentHTML('afterbegin', element);
            }
            break;

        case 'before':
            if (element instanceof Node) {
                parentElement.parentNode.insertBefore(element, parentElement);
            } else {
                parentElement.insertAdjacentHTML('beforebegin', element);
            }
            break;

        case 'after':
        case 'replace':
            if (element instanceof Node) {
                parentElement.parentNode.insertBefore(element, parentElement.nextSibling);
            } else {
                parentElement.insertAdjacentHTML('afterend', element);
            }

            if (renderMode === 'replace') {
                parentElement.parentNode.removeChild(parentElement);
            }
            break;

        default:
            throw new Error(`Unsupported renderMode for render(): ${renderMode}`);
    }

    if (typeof element === 'string') {
        setTimeout(async () => {
            const scriptRegex = /<script.*?src=("|')(.*?)("|').*?><\/script>/ig;
            let matches;
            while (null !== (matches = scriptRegex.exec(element))) {
                try {
                    await addScript(matches[2]);
                } catch {}
            }
            element.evalScripts();
        }, 10);
    }
};

export default render;
