// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { Component } from './component';
import { SmallButton } from './small-button';
import render from './render';

export class DynamicSubForm extends Component {
    _initConfiguration(config) {
        super._initConfiguration(config);
        const getDynamicContent = this._getConfigParam('getDynamicContent', null);
        if (getDynamicContent) {
            this._getDynamicContent = getDynamicContent.bind(this);
        }
        const prepareDynamicContent = this._getConfigParam('prepareDynamicContent', null);
        if (prepareDynamicContent) {
            this._prepareDynamicContent = prepareDynamicContent.bind(this);
        }
        const bindDynamicContent = this._getConfigParam('bindDynamicContent', null);
        if (bindDynamicContent) {
            this._bindDynamicContent = bindDynamicContent.bind(this);
        }
        const insertDynamicContent = this._getConfigParam('insertDynamicContent', null);
        if (insertDynamicContent) {
            this._insertDynamicContent = insertDynamicContent.bind(this);
        }
        const onUpdateDynamic = this._getConfigParam('onUpdateDynamic', null);
        if (onUpdateDynamic) {
            this._onUpdateDynamic = onUpdateDynamic.bind(this);
        }
        const onInitDynamic = this._getConfigParam('onInitDynamic', null);
        if (onInitDynamic) {
            this._onInitDynamic = onInitDynamic.bind(this);
        }
    }

    _addEvents() {
        super._addEvents();
        const dynamicElements = this.getElements();
        if (!dynamicElements.length) {
            this._addEmptyContent();
        } else {
            dynamicElements.forEach((element, index) => {
                this._bindDynamicContent(element, !index);
            });
        }
        this._onInitDynamic();
        this._onUpdateDynamic();
    }

    _addEmptyContent() {
        const element = this._prepareDynamicContent(this._getDynamicContent());
        this._insertDynamicContent(element);
        this._bindDynamicContent(element, true);
    }

    _getDynamicContent() {
        const dynamicElement = this._componentElement.querySelector('.dynamicSubFormTemplate').cloneNode(true);
        dynamicElement.classList.remove('dynamicSubFormTemplate');
        dynamicElement.classList.add('dynamicSubFormContent');
        dynamicElement.style.display = '';
        return dynamicElement;
    }

    _prepareDynamicContent(content) {
        return content;
    }

    _insertDynamicContent(content) {
        const dynamicElements = this.getElements();
        if (!dynamicElements.length) {
            render(this._componentElement, content, 'top');
        } else {
            render(dynamicElements[dynamicElements.length - 1], content, 'after');
        }
    }

    _bindDynamicContent() {
        // basic empty implementation
    }

    _onInitDynamic() {
        // basic empty implementation
    }

    _onUpdateDynamic() {
        // basic empty implementation
    }

    deleteContent(content) {
        content.parentNode.removeChild(content);
        this._onUpdateDynamic();
    }

    addContent(value) {
        const content = this._prepareDynamicContent(this._getDynamicContent(), value);
        this._insertDynamicContent(content);
        this._bindDynamicContent(content, false);
        this._onUpdateDynamic();
    }

    reset() {
        const dynamicElements = this.getElements();
        if (dynamicElements.length) {
            dynamicElements.forEach(element => {
                element.parentNode.removeChild(element);
            });
            this._onUpdateDynamic();
        }
    }

    getElements() {
        return this._componentElement.querySelectorAll('.dynamicSubFormContent');
    }
}

export class AddRemoveDynamicSubForm extends DynamicSubForm {
    _initConfiguration(config) {
        super._initConfiguration(config);
        this._addButtonId = this._getConfigParam('addButtonId', '');
        this._addButtonTitle = this._getConfigParam('addButtonTitle', '');
        this._removeButtonId = this._getConfigParam('removeButtonId', '');
        this._removeButtonTitle = this._getConfigParam('removeButtonTitle', '');
    }

    _onInitDynamic() {
        const addButton = document.getElementById(this._addButtonId);
        render(addButton, `<div id="${this._addButtonId}" class="text-value"></div>`, 'after');
        addButton.parentNode.removeChild(addButton);

        new SmallButton({
            id: `dynamicSubFormContentAddButton-${this._id}`,
            title: this._addButtonTitle,
            addCls: 'sb-item-add dynamicSubFormContentAddButton',
            handler: this.addContent.bind(this),
            disabled: this._isAddButtonDisabled(),
            renderTo: this._addButtonId,
        });
    }

    _isAddButtonDisabled() {
        return false;
    }

    _bindDynamicContent(content, isFirstElement) {
        if (!isFirstElement) {
            const wrapperId = this._initRemoveButtonWrapper(content);
            new SmallButton({
                id: wrapperId,
                title: this._removeButtonTitle,
                addCls: 'sb-item-remove dynamicSubFormContentRemoveButton',
                handler: this.deleteContent.bind(this, content),
                renderTo: wrapperId,
            });
        }
        return content;
    }

    _initRemoveButtonWrapper(content) {
        const wrapperId = `${this._removeButtonId}-${this._getRandomNumber()}`;
        const inputEls = content.querySelectorAll('.field-value input, .field-value select');
        render(inputEls[inputEls.length - 1], `<span id="${wrapperId}" class="${this._removeButtonId}"></span>`, 'after');

        return wrapperId;
    }

    _prepareDynamicContent(content) {
        const newId = Math.floor(1000000 * Math.random());

        const nameRegExp = new RegExp(`(name=.*?\\[?${this._id}\\]?\\[)dynamicSubFormTemplate(\\])`, 'g');
        const idRegExp = new RegExp(`(id=.*?\\-?${this._id}-)dynamicSubFormTemplate(-)`, 'g');

        content.innerHTML = content.innerHTML
            .replace(nameRegExp, `\$1c${newId}\$2`)
            .replace(idRegExp, `\$1c${newId}\$2`);

        return content;
    }

    _onUpdateDynamic() {
        const children = this.getElements();
        document.querySelector(`label[for="${this._addButtonId}"]`).style.display = children.length ? 'none' : '';
        if (children.length) {
            children.forEach((child, index) => {
                const labels = child.querySelectorAll('label');
                labels[labels.length - 1].style.display = index ? 'none' : '';
            });
        }
    }

    addContent(event) {
        super.addContent();
        event.stopPropagation();
        event.preventDefault();
    }

    deleteContent(content, event) {
        super.deleteContent(content);
        event.stopPropagation();
        event.preventDefault();
    }
}

// TODO EXTDOCKER-50: Cannot open docker extension: Cannot read property 'push' of undefined
DynamicSubForm.subclasses = [];
