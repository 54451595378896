// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { Component } from './component';
import fireCustomEvent from './fireCustomEvent';

const symbolClasses = {
    upper: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    lower: 'abcdefghijklmnopqrstuvwxyz',
    number: '0123456789',
    special: '!@#$%^&*?_~',
};

export class PasswordGenerator extends Component {
    _initConfiguration(config) {
        super._initConfiguration({ tag: 'span', ...config });
        this._passwordElement = document.getElementById(this._getConfigParam('password', null));
        this._passwordConfirmationElement = document.getElementById(this._getConfigParam('passwordConfirmation', null));
        this._generateButtonElement = document.getElementById(this._getConfigParam('generateButton', null));
        this._generateButtonElement.addEventListener('click', this._onGeneratePasswordClick.bind(this));
        this._showButtonElement = document.getElementById(this._getConfigParam('showButton', null));
        this._showButtonElement.addEventListener('click', this._onShowPasswordClick.bind(this));
        this._showPasswordVisible = true;
        this._showPasswordTitle = this._getConfigParam('showPasswordTitle', '');
        this._hidePasswordTitle = this._getConfigParam('hidePasswordTitle', '');
        this._passwordStrength = this._getConfigParam('passwordStrength', '');
    }

    _onGeneratePasswordClick() {
        const password = this._generatePassword();
        this._passwordElement.value = password;
        if (this._passwordConfirmationElement) {
            this._passwordConfirmationElement.value = password;
        }
        fireCustomEvent(this._passwordElement, 'plesk:passwordGenerated');
    }

    _onShowPasswordClick() {
        if (this._showPasswordVisible) {
            this._passwordElement.setAttribute('type', 'text');
            this._showButtonElement.innerHTML = this._hidePasswordTitle;
        } else {
            this._passwordElement.setAttribute('type', 'password');
            this._showButtonElement.innerHTML = this._showPasswordTitle;
        }
        this._showPasswordVisible = !this._showPasswordVisible;
    }

    _generatePassword() {
        let password = '';
        const requiredClasses = ['upper', 'lower', 'lower', 'lower', 'number', 'number', 'special', 'number'];

        if ('Stronger' === this._passwordStrength) {
            requiredClasses.push('upper', 'upper', 'lower', 'lower', 'lower', 'lower', 'lower', 'lower');
        }

        requiredClasses.sort((a, b) => Math.floor(b.length * Math.random()) - Math.floor(a.length * Math.random()));
        requiredClasses.forEach(i => {
            password += this._generateSymbol(i);
        });
        return password;
    }

    _generateSymbol(symbolClass) {
        const index = Math.floor(symbolClasses[symbolClass].length * Math.random());
        return symbolClasses[symbolClass][index];
    }
}
