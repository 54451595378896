// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import TopBarProgress from 'react-topbar-progress-indicator';
import { matchPath, useLocation, useHistory } from 'react-router-dom';
import { redirect, Tooltip } from 'jsw';
import routes from '../routes';
import { isMatch } from './Link';

TopBarProgress.config({
    barThickness: 2,
    barColors: {
        0: '#28aade',
        1.0: '#28aade',
    },
    shadowBlur: 0,
    className: 'top-bar-progress',
});

const updateBottomAnchor = () => {
    const bottomAnchor = document.getElementById('bottomAnchor');

    if (bottomAnchor) {
        bottomAnchor.innerHTML = new Date().getTime().toString();
    }
};

const Routes = ({ children }) => {
    const [current, setCurrent] = useState(null);
    const lastLoadedRoute = useRef(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const location = useLocation();

    const route = routes.find(route => matchPath(location.pathname, route)) || null;

    const load = route => {
        if (location.state?.reload) {
            delete location.state.reload;
            history.replace(location);
        }
        lastLoadedRoute.current = route;
        setLoading(true);
        route.element(matchPath(location.pathname, route))
            .then(element => {
                if (route !== lastLoadedRoute.current) {
                    return;
                }

                if (element.redirect) {
                    if (isMatch(element.redirect)) {
                        history.replace(element.redirect);
                    } else {
                        redirect(element.redirect);
                    }
                } else {
                    Tooltip.hide();
                    setCurrent(element);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                throw e;
            });
    };

    useEffect(() => {
        if (!route && current !== null && history.action === 'POP') {
            window.location.reload();
        } else if (!route) {
            setLoading(false);
            setCurrent(children);
        } else if (route !== lastLoadedRoute.current || location.state?.reload) {
            load(route);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route, location.state?.reload]);

    useEffect(() => {
        if (!loading) {
            setTimeout(updateBottomAnchor, 0);
        }
    }, [loading]);

    return (
        <>
            {loading && <TopBarProgress />}
            {current}
        </>
    );
};

Routes.propTypes = {
    children: PropTypes.any,
};

Routes.defaultProps = {
    children: undefined,
};

export default Routes;
