// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { Component } from './component';
import { DropdownManager } from './dropdown-manager';
import Cookie from 'common/cookie';
import prepareUrl from './prepareUrl';
import render from './render';
import ce from './createElement';
import escapeHtml from './escapeHtml';
import api from './api';
import Locale from './Locale';
import addStatusMessage from './addStatusMessage';
import pageData from '../helpers/pageData';
import emptyFn from './emptyFn';

import './active-list.less';

/**
 * Example usage:
 *
 *     @example
 *     new ActiveList({
 *         renderTo: document.body,
 *         toolbar: {
 *             buttons: {
 *                 btn1: {
 *                     title: 'Add new item',
 *                     url: '#!'
 *                 }
 *             }
 *         },
 *         layout: 'responsivecolumn',
 *         data: [{
 *             title: 'Item 1',
 *             icon: 'class-icons/component-large.png',
 *             buttons: [{
 *                 title: 'Action 1',
 *                 icon: 'class-icons/class-large.png'
 *             }, {
 *                 title: 'Action 2',
 *                 icon: 'class-icons/singleton-large.png'
 *             }]
 *         }, {
 *             title: 'Item 2',
 *             icon: 'class-icons/component-large.png',
 *             buttons: [{
 *                 title: 'Action 1',
 *                 icon: 'class-icons/class-large.png'
 *             }, {
 *                 title: 'Action 2',
 *                 icon: 'class-icons/singleton-large.png'
 *             }]
 *         }]
 *     });
 */
export class ActiveList extends Component {
    static stateCollapsed = (id, defaultValue = false) => {
        const cookieName = `${id}-state-collapsed`;
        let stateCollapsed = Cookie.get(cookieName);

        try {
            stateCollapsed = JSON.parse(stateCollapsed) || {};
        } catch {
            stateCollapsed = {};
        }

        return {
            get(item) {
                return stateCollapsed[item.id] === undefined ? defaultValue : Boolean(stateCollapsed[item.id]);
            },
            set(item, value) {
                if (value === defaultValue) {
                    delete stateCollapsed[item.id];
                } else {
                    stateCollapsed[item.id] = value ? 1 : 0;
                }
                Cookie.setPermanent(cookieName, JSON.stringify(stateCollapsed), '/');
            },
        };
    };

    _initConfiguration(config) {
        super._initConfiguration(config);

        this.urls = this._getConfigParam('urls', {});
        this.navigate = this._getConfigParam('onNavigate', emptyFn);

        this.isCollapsible = this._getConfigParam('isCollapsible', false);
        this.isNew = this._getConfigParam('isNew', false);
        this.isShowItemTitle = this._getConfigParam('isShowItemTitle', true);
        this.layout = this._getConfigParam('layout', 'auto');
        if (typeof this.layout === 'string') {
            this.layout = {
                type: this.layout,
            };
        }

        this.stateCollapsed = ActiveList.stateCollapsed(this.getId());

        this.itemActions = this._getConfigParam('itemActions', {});
        this.data = this._getConfigParam('data', []);

        this._cls = `active-list${this.isCollapsible ? ' active-list-collapsible' : ''}`;
    }

    _initComponentElement() {
        super._initComponentElement();

        render(this._componentElement, this.view());
    }

    view() {
        return ce('.active-list-wrap',
            this.toolbarView(this._getConfigParam('toolbar')),
            this.itemsView(),
            this.bottomBarView(this._getConfigParam('countMoreItems', 0)),
        );
    }

    toolbarView(toolbar) {
        if (!toolbar) {
            return '';
        }

        return ce('.a-toolbar.a-toolbar-collapsed',
            ce('.a-toolbar-wrap',
                ce('.a-toolbar-buttons',
                    ce('.a-toolbar-buttons-wrap',
                        ce('table.a-toolbar-buttons-table[cellspacing=0]',
                            ce('tbody',
                                ce('tr',
                                    ce('td.a-toolbar-buttons-table-main',
                                        ce('.a-toolbar-buttons-main',
                                            Object.entries(toolbar.buttons).map(([id, { title, url, intent }]) => ce(
                                                `a.btn${intent ? `.btn-${intent}` : ''}`,
                                                {
                                                    id,
                                                    href: url,
                                                    onclick: this.navigate,
                                                },
                                                ` ${escapeHtml(title)}`,
                                            )),
                                        ),
                                    ),
                                    ce('td.a-toolbar-buttons-table-misc',
                                        ce('.a-toolbar-buttons-misc',
                                            ce('.a-toolbar-buttons-settings.a-toolbar-buttons-settings-inactive',
                                                ce('button.btn.btn-settings[type=button]', { title: this.lmsg('settingsTitle') },
                                                    ce('i.icon', ce('img[alt=""]', {
                                                        src: require('icons/16/plesk/settings-toolbar.png'),
                                                    })),
                                                ),
                                                this.isNew ? ce('span.badge-new', this.lmsg('badgeNew')) : '',
                                                ce('.popup-box.a-toolbar-buttons-settings-popup',
                                                    ce('table.popup-wrapper[cellspacing=0]', ce('tbody', ce('tr', ce('td.popup-container',
                                                        ce('.c1', ce('.c2', ce('.c3', ce('.c4', ce('.c5',
                                                            ce('.popup-content',
                                                                ce('.popup-content-area',
                                                                    ce('.settings-list',
                                                                        ce('.settings-item.settings-item-1',
                                                                            ce('h3.settings-item-title',
                                                                                ce('span', `${this.lmsg('sortTitle')}:`),
                                                                            ),
                                                                            ce(`a.btn${toolbar.sortDir === 'up' ? '.btn-state-selected' : ''}`,
                                                                                { href: this.urls['sort-up'] },
                                                                                ce('i.icon', ce('img[alt=""]', {
                                                                                    src: require('icons/16/plesk/order-ascending.png'),
                                                                                })),
                                                                                this.lmsg('orderAscending'),
                                                                            ),
                                                                            ce(`a.btn${toolbar.sortDir === 'down' ? '.btn-state-selected' : ''}`,
                                                                                { href: this.urls['sort-down'] },
                                                                                ce('i.icon', ce('img[alt=""]', {
                                                                                    src: require('icons/16/plesk/order-descending.png'),
                                                                                })),
                                                                                this.lmsg('orderDescending'),
                                                                            ),
                                                                        ),
                                                                        (toolbar.hasClassicView || toolbar.hasDynamicView) ? ce('.settings-item.settings-item-2',
                                                                            ce('h3.settings-item-title',
                                                                                ce('span', `${this.lmsg('modeTitle')}:`),
                                                                            ),
                                                                            toolbar.hasDynamicView ? ce('a.btn', { href: '?list-type=dynamic' },
                                                                                ce('i.icon', ce('img[alt=""]', {
                                                                                    src: require('icons/16/plesk/list-type-dynamic.png'),
                                                                                })),
                                                                                this.lmsg('typeDynamic'),
                                                                            ) : null,
                                                                            ce('a.btn.btn-state-selected',
                                                                                ce('i.icon', ce('img[alt=""]', {
                                                                                    src: require('icons/16/plesk/list-type-active.png'),
                                                                                })),
                                                                                this.lmsg('typeActive'),
                                                                            ),
                                                                            toolbar.hasClassicView ? ce('a.btn', { href: '?list-type=classic' },
                                                                                ce('i.icon', ce('img[alt=""]', {
                                                                                    src: require('icons/16/plesk/list-type-classic.png'),
                                                                                })),
                                                                                this.lmsg('typeClassic'),
                                                                            ) : null,
                                                                        ) : '',
                                                                    ),
                                                                ),
                                                            ),
                                                        ))))),
                                                    )))),
                                                ),
                                            ),
                                        ),
                                    ),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        );
    }

    itemsView() {
        if (!this.data.length) {
            return this.emptyView();
        }

        const items = this.data.map(this.itemView.bind(this));
        if (this.layout.type !== 'responsivecolumn') {
            return items;
        }

        let cls = ['.grid'];
        if (this.layout.stretched !== false) {
            cls.push('.grid-stretched');
        }
        cls = cls.concat((this.layout.columns || 'xl-2 xxl-3').split(' ').map(cls => `.grid-${cls}`));

        return ce(cls.join(''),
            items.map(item => ce('.grid-col', item)),
        );
    }

    emptyView() {
        return ce('p', this.lmsg('noObjects'));
    }

    itemView(item) {
        const itemClasses = [
            '.active-list-item',
        ];
        const types = [
            'danger',
            'warning',
            'inactive',
            'success',
        ];

        if (item.type && types.indexOf(item.type) > -1) {
            itemClasses.push(`.active-list-item-${item.type}`);
        }
        if (this.isCollapsible && item.buttons) {
            itemClasses.push('.active-list-item-collapsible');
            itemClasses.push(this.stateCollapsed.get(item) ? '.active-list-item-collapsed' : '.active-list-item-expanded');
        } else {
            itemClasses.push('.active-list-item-expanded');
        }

        return ce(`#active-list-item-${item.id}${itemClasses.join('')}`, { style: 'position:relative' },
            ce('.content-blocker', { style: 'display:none;' }),
            ce('.active-list-item-wrap',
                this.itemCaptionView(item),
                this.itemDetailsView(item),
            ),
        );
    }

    itemDetailsView(item) {
        const children = [];
        if (item.settings && item.settings.length) {
            children.push(this.itemSettingsView(item));
        }
        if (item.buttons && item.buttons.length) {
            children.push(this.itemToolsView(item));
        }

        if (!children.length) {
            return '';
        }

        return ce('.active-list-details',
            ce('.active-list-details-wrap',
                children,
            ),
        );
    }

    itemSettingsView(item) {
        return ce('.list',
            ce(`table#active-list-item-settings-table-${item.id}[width=100%][cellspacing=0]`,
                ce('tbody',
                    item.settings.map((settings, index) => ce(`tr${index % 2 ? '.even' : '.odd'}`,
                        ce('td.min',
                            ce('span.tooltipData', escapeHtml(settings.hint)),
                            ce(`a.s-btn.${settings.button}[data-method=post]`, { href: settings.url }, ce('span')),
                        ),
                        ce('td', escapeHtml(settings.name)),
                        ce('td', escapeHtml(settings.description)),
                    )),
                ),
            ),
        );
    }

    itemToolsView(item) {
        return ce('.tools-list-box',
            ce('.box-area',
                ce('.content',
                    ce('.content-area',
                        ce('ul.tools-list',
                            item.buttons.map(button => {
                                let onClick = null;
                                if (button.name && this.itemActions[button.name]) {
                                    onClick = event => {
                                        event.preventDefault();
                                        this.itemActions[button.name](item, event);
                                    };
                                } else if (button.handler) {
                                    onClick = typeof button.handler === 'function'
                                        ? button.handler
                                        : `(${button.handler}(event)); return false;`;
                                } else {
                                    onClick = this.navigate;
                                }

                                return ce('li.tools-item',
                                    ce(`a.tool-block${button.disabled ? '.disabled' : ''}`,
                                        {
                                            href: !button.disabled && button.href ? button.href : null,
                                            onclick: button.disabled ? null : onClick,
                                            target: button.target || null,
                                            'data-identity': button.id || null,
                                            'data-action-name': button.name || null,
                                            rel: button.target === '_blank' ? 'noopener noreferrer' : null,
                                        },
                                        ce('span.tool-icon', ce('img[alt=""]', { src: escapeHtml(button.icon) })),
                                        ce('span.tool-name', button.noEscapeTitle ? button.title : escapeHtml(button.title)),
                                        button.new ? ce('span.badge-new', this.lmsg('badgeNew')) : '',
                                        Array.isArray(button.additionalComments) ? ce('.span.tool-info',
                                            button.additionalComments
                                                .map(comment => button.noEscape ? comment : escapeHtml(comment))
                                                .join('<br>'),
                                        ) : '',
                                    ),
                                    button.comment ? ce('span.tooltipData', escapeHtml(button.comment)) : '',
                                );
                            }),
                        ),
                    ),
                ),
            ),
        );
    }

    bottomBarView(countMoreItems) {
        if (!countMoreItems) {
            return '';
        }

        return ce('.active-list-bottom-bar',
            ce('.active-list-bottom-bar-wrap', this.lmsg('showAll', {
                countMoreItems,
                loadAllLink: `<a href="?all=">${this.lmsg('loadAll')}</a>`,
                switchToLink: `<a href="?list-type=classic">${this.lmsg('switchTo', { listType: this.lmsg('typeClassic') })}</a>`,
            })),
        );
    }

    itemCaptionView(item) {
        return ce('.caption',
            ce('.caption-wrap',
                this.itemCaptionHeaderView(item),
                this.itemCaptionServicesView(item),
                this.itemCaptionDataView(item),
                this.itemCaptionAdditionalHtml(item),
                Array.isArray(item.buttons) && item.buttons.length ? (
                    this.isCollapsible ? ce('.caption-control',
                        {
                            onclick: this.toggleItem.bind(this, item),
                        },
                        ce('span.caption-control-wrap',
                            ce('i'), ' ',
                            ce('span.caption-control-on', this.lmsg('showMore')),
                            ce('span.caption-control-off', this.lmsg('showLess')),
                        ),
                    ) : ''
                ) : '',
            ),
        );
    }

    itemCaptionHeaderView(item) {
        return ce('.caption-header',
            ce('.caption-widget'),
            ce('.caption-header-wrap',
                ce('.caption-main',
                    item.icon ? ce('.caption-icon',
                        ce('span', ce('img[alt=""]', { src: item.icon })),
                    ) : '',
                    this.itemCaptionHeadView(item),
                ),
            ),
        );
    }

    itemCaptionHeadView(item) {
        return ce('.caption-head',
            this.isShowItemTitle ? this.itemCaptionHeadWrapView(item) : '',
            this.itemCaptionSummaryView(item),
            this.itemCaptionToolbarView(item),
        );
    }

    itemCaptionHeadWrapView(item) {
        let labels = [];
        const labelTypes = ['danger', 'warning', 'success', 'info', 'inactive'];

        if (Array.isArray(item.labels) && item.labels.length) {
            labels = item.labels.map(function (label) {
                const type = label.type && labelTypes.indexOf(label.type) > -1 ? label.type : '';
                const cls = type === '' ? '' : (`.label-${type}`);

                return ce(`span.label${cls}`, label.value || '');
            });
        }

        return ce('.caption-head-wrap',
            {
                onclick: this.isCollapsible && item.buttons ? this.onCaptionHeadWrapClick.bind(this, item) : null,
            },
            ce('h2.caption-name',
                ce('span', escapeHtml(item.title)),
            ),
            labels,
            Array.isArray(item.primaryActions) && item.primaryActions.length ? ce('span.caption-head-widget',
                item.primaryActions.map(function (action) {
                    return [ce('a.btn', { href: action.link },
                        action.title,
                    ), ' '];
                }),
            ) : '',
        );
    }

    itemCaptionSummaryView(item) {
        return Array.isArray(item.summary) && item.summary.length ? ce('.caption-summary',
            ce('.caption-summary-wrap',
                item.summary.map(this.summaryItemView.bind(this)),
            ),
        ) : item.summary || '';
    }

    summaryItemView(summaryItem) {
        return ce('span.summary-item', `${summaryItem.name}: <b>${summaryItem.value}</b>`);
    }

    itemCaptionToolbarView(item) {
        return Array.isArray(item.toolbar) && item.toolbar.length ? ce('.caption-toolbar',
            ce('.caption-toolbar-wrap',
                item.toolbar.map(this.toolbarButtonView.bind(this)),
            ),
        ) : '';
    }

    toolbarButtonView(button) {
        return ce(`a.i-link${button.action ? `[data-item-action="${button.action}"]` : ''}`, { href: button.link || null },
            button.icon || button.iconClass ? [ce(`i${button.iconClass ? `.${button.iconClass}` : 'icon'}`,
                button.icon ? ce('img[alt=""]', { src: button.icon }) : '',
            ), ' '] : '',
            ce('span', escapeHtml(button.title)),
        );
    }

    itemCaptionServicesView(item) {
        return Array.isArray(item.services) ? item.services.map(this.itemServiceView.bind(this)) : '';
    }

    itemServiceView(service) {
        return ce(`.caption-services${service.class ? `.${service.class}` : ''}`, { style: service.hidden ? 'display:none' : '' },
            ce('.caption-services-wrap',
                service.header ? ce(service.headerClass ? `.${service.headerClass}` : '',
                    service.closeUrl ? ce('span.close', { 'data-close-url': service.closeUrl }) : '',
                    service.header,
                ) : '',
                this.itemServiceBlockView(service),
            ),
        );
    }

    itemServiceBlockView(service) {
        if (service.grid) {
            const columns = service.grid.reduce(function (columns, service) {
                return columns + (service.colspan || 1);
            }, 0);
            return ce(`.b-grid${service.grid.length > 1 ? `.b-grid-${columns}` : ''}`,
                ce('ul.b-grid-list',
                    service.grid.map(function (subService) {
                        return ce(`li.b-grid-item${service.grid.length > 1 && subService.colspan ? `.b-grid-item-${subService.colspan}` : ''}`,
                            ce('.b-grid-item-wrap', this.itemServiceBlockView(subService)),
                        );
                    }.bind(this)),
                ),
            );
        }

        if (service.type) {
            return this[`itemService${service.type.capitalize().camelize()}View`](service);
        }

        let icon = ce('img[alt=""]', { src: service.icon });
        let name = escapeHtml(service.title);
        if (service.href) {
            icon = ce(`a${service.newWindow ? '[target=_blank]' : ''}`, { href: service.href, onclick: this.navigate }, icon);
            name = ce(`a${service.newWindow ? '[target=_blank]' : ''}`, { href: service.href, onclick: this.navigate }, name);
        }

        const description = service.description ? service.description : '';
        return ce('.caption-service-block',
            ce('span.caption-service-title',
                ce('i.caption-service-icon', icon),
                ce('span.caption-service-name', name, description),
            ),
            Array.isArray(service.links) && service.links.length ? ce('.caption-service-toolbar',
                service.links.map(function (link) {
                    let linkView;
                    if (link.childLinks && link.childLinks.length) {
                        linkView = ce('.btn-group',
                            ce('a.btn', {
                                href: escapeHtml(link.href),
                                target: link.newWindow ? '_blank' : null,
                            }, escapeHtml(link.title)),
                            ce('button.btn.dropdown-toggle[type=button]', ce('em.caret')),
                            ce('ul.dropdown-menu',
                                link.childLinks.map(function (childLink) {
                                    return ce('li',
                                        ce('a', {
                                            href: escapeHtml(childLink.href),
                                            target: childLink.newWindow ? '_blank' : null,
                                        }, escapeHtml(childLink.title)),
                                    );
                                }),
                            ),
                        );
                    } else {
                        const icon = link.icon ? [ce('i.icon', ce('img[alt=""]', { src: escapeHtml(link.icon) })), ' '] : '';
                        if (link.isSimpleText) {
                            linkView = ce('span',
                                icon,
                                link.noEscape ? link.info : escapeHtml(link.info),
                            );
                        } else if (link.isSimpleLink) {
                            linkView = ce(`span${link.spanClass ? `.${link.spanClass}` : ''}`,
                                icon,
                                `${escapeHtml(link.info)} `,
                                ce('a', {
                                    href: escapeHtml(link.href),
                                    class: link.class ? escapeHtml(link.class) : null,
                                    onclick: link.onClick ? escapeHtml(link.onClick) : null,
                                    target: link.newWindow ? '_blank' : null,
                                }, escapeHtml(link.title)),
                            );
                        } else {
                            linkView = ce('a.btn', {
                                href: escapeHtml(link.href),
                                class: link.class ? escapeHtml(link.class) : null,
                                onclick: link.onClick ? escapeHtml(link.onClick) : null,
                                target: link.newWindow ? '_blank' : null,
                                id: link.id ? escapeHtml(link.id) : null,
                                name: link.name ? escapeHtml(link.name) : null,
                            }, icon, escapeHtml(link.title));
                        }
                    }

                    return [
                        link.newLine ? ce('br') : '',
                        linkView,
                        ' ',
                    ];
                }),
            ) : '',
        );
    }

    itemCaptionDataView(item) {
        if (!item.data || !item.data.length) {
            return '';
        }

        return ce('.caption-data',
            ce('.caption-data-wrap',
                item.data.map(function (data, index) {
                    return ce('.form-group',
                        ce(`label.control-label[for=active-list-data-${item.id}-${index}]`, data.label),
                        ce(`textarea#active-list-data-${item.id}-${index}.form-control.code[rows=${data.linesNumber}][readonly]`, escapeHtml(data.text)),
                    );
                }),
            ),
        );
    }

    itemCaptionAdditionalHtml(item) {
        if (!item.additionalHtml) {
            return '';
        }

        return ce('.caption-data',
            ce('.caption-data-wrap', item.additionalHtml),
        );
    }

    onCaptionHeadWrapClick(item, event) {
        if (event && event.target.closest('.caption-head-action, .caption-head-status, .caption-head-widget')) {
            return;
        }

        this.toggleItem(item);

        const el = this.getItemElement(item);
        if (el.classList.contains('active-list-item-collapsed')) {
            return;
        }

        const itemBottom = Element.cumulativeOffset(el).top + Element.getHeight(el);
        const viewportBottom = document.viewport.getScrollOffsets().top + document.viewport.getHeight();
        if (itemBottom + 40 > viewportBottom) {
            this.scrollToItem(el, 500);
        }
    }

    getItemElement(item) {
        return this._componentElement.querySelector(`#active-list-item-${String(item.id).replace(/:/g, '\\:')}`);
    }

    _addEvents() {
        this._componentElement.querySelectorAll('.dropdown-toggle').forEach(element => {
            element.addEventListener('click', event => {
                event.stopPropagation();
                event.preventDefault();
                const hadClassName = event.target.closest('.btn-group').classList.contains('btn-group-open');
                document.querySelectorAll('.active-list .dropdown-toggle').forEach(elem => {
                    elem.closest('.btn-group').classList.remove('btn-group-open');
                });
                if (!hadClassName) {
                    event.target.closest('.btn-group').classList.add('btn-group-open');
                }
            });
            document.body.addEventListener('click', () => {
                element.closest('.btn-group').classList.remove('btn-group-open');
            });
        });

        this._componentElement.querySelectorAll('.a-toolbar-buttons-misc .btn-search').forEach(element => {
            element.addEventListener('click', () => {
                element.closest('.a-toolbar').classList.toggle('a-toolbar-collapsed');
                element.closest('.a-toolbar').classList.toggle('a-toolbar-expanded');
            });
        });

        this._componentElement.querySelectorAll('.a-toolbar-buttons-misc .btn-settings').forEach(el => {
            DropdownManager.register(el,
                function () {
                    return el.parentNode.classList.contains('a-toolbar-buttons-settings-active');
                },
                function () {
                    el.parentNode.classList.add('a-toolbar-buttons-settings-active');
                    el.parentNode.classList.remove('a-toolbar-buttons-settings-inactive');
                },
                function () {
                    el.parentNode.classList.add('a-toolbar-buttons-settings-inactive');
                    el.parentNode.classList.remove('a-toolbar-buttons-settings-active');
                },
            );
        });

        this._componentElement.querySelectorAll('.caption-services .close').forEach(element => {
            element.addEventListener('click', event => {
                event.stopPropagation();
                event.preventDefault();
                const { closeUrl } = event.target.dataset;
                event.target.closest('.caption').querySelectorAll('.caption-services').forEach(element => {
                    element.style.display = '';
                });
                const servicesEl = event.target.closest('.caption-services');
                servicesEl.parentNode.removeChild(servicesEl);
                api.post(prepareUrl(closeUrl));
            });
        });

        this._initToolbar();
    }

    disableItem(id) {
        this.getItemElement({ id }).querySelector('.content-blocker').style.display = '';
    }

    enableItem(id) {
        this.getItemElement({ id }).querySelector('.content-blocker').style.display = 'none';
    }

    _initToolbar() {
        this._componentElement.querySelectorAll('.a-toolbar-buttons-main').forEach(toolbarEl => {
            const itemEls = [...toolbarEl.children];

            const menuEl = new Element('span', { class: 'btn-group' });
            menuEl.innerHTML = (
                '<button type="button" class="btn dropdown-toggle">' +
                    `<i class="icon"><img src="${require('icons/16/plesk/menu.png')}" alt=""></i>` +
                    ' <em class="caret"></em>' +
                '</button>' +
                '<ul class="dropdown-menu pull-right"></ul>'
            );

            menuEl.addEventListener('click', event => {
                event.stopPropagation();
                menuEl.classList.toggle('btn-group-open');

                const dropdownMenu = menuEl.querySelector('.dropdown-menu');
                if (Element.getStyle(dropdownMenu, 'display') !== 'none') {
                    dropdownMenu.style.right = 0;
                    const offset = Element.cumulativeOffset(dropdownMenu);
                    const { left } = Element.cumulativeOffset(toolbarEl);
                    if (offset.left < left) {
                        dropdownMenu.style.right = `${offset.left - left}px`;
                    }
                }
            });
            document.body.addEventListener('click', () => {
                menuEl.classList.remove('btn-group-open');
            });
            render(toolbarEl, menuEl);

            const layout = Element.getLayout(menuEl);
            toolbarEl._menuEl = {
                el: menuEl,
                width: Element.getWidth(menuEl) + layout.get('margin-left') + layout.get('margin-right'),
            };
            menuEl.style.display = 'none';

            toolbarEl._items = [];
            itemEls.forEach(itemEl => {
                const itemElClone = itemEl.cloneNode(true);
                itemElClone.classList.remove('btn');

                const liEl = new Element('li');
                render(liEl, itemElClone);
                render(menuEl.querySelector('ul'), liEl);

                const layout = Element.getLayout(itemEl);
                toolbarEl._items.push({
                    el: itemEl,
                    elInMenu: liEl,
                    width: Element.getWidth(itemEl) + layout.get('margin-left') + layout.get('margin-right'),
                });
            });
        });

        const onWindowResize = () => {
            document.querySelectorAll('.a-toolbar-buttons-main').forEach(toolbarEl => {
                let width = Element.getWidth(toolbarEl);
                width -= toolbarEl._menuEl.width;

                toolbarEl._items.each(function (item) {
                    if (width > item.width) {
                        item.el.style.display = '';
                        item.elInMenu.style.display = 'none';
                        width -= item.width;
                    } else {
                        item.el.style.display = 'none';
                        item.elInMenu.style.display = '';
                        width = -1;
                    }
                });
                if (width === -1) {
                    toolbarEl._menuEl.el.style.display = '';
                } else {
                    toolbarEl._menuEl.el.style.display = 'none';
                }
            });
        };

        onWindowResize();
        window.addEventListener('resize', onWindowResize);
    }

    toggleItem(item) {
        this.getItemElement(item).classList.toggle('active-list-item-collapsed');
        this.getItemElement(item).classList.toggle('active-list-item-expanded');
        this.stateCollapsed.set(item, !this.stateCollapsed.get(item));
    }

    scrollToItem(el, duration) {
        const startTime = new Date().getTime();
        const from = document.viewport.getScrollOffsets().top;
        let to = Element.cumulativeOffset(el).top - Element.cumulativeOffset(document.querySelector('.pul-layout__main')).top - 40;

        if (document.getElementById('classic-mode-navigation')) {
            to -= Element.getHeight(document.getElementById('classic-mode-navigation'));
        }

        const frame = () => {
            let progress = duration ? (new Date().getTime() - startTime) / duration : 1;
            if (progress > 1) {
                progress = 1;
            }

            window.scrollTo(0, Math.round(from + ((to - from) * progress)));

            if (progress < 1) {
                setTimeout(frame, 50);
            }
        };
        frame();
    }

    getItemIdFromUrl(url) {
        const m = url.match('/id/([0-9]+)');
        return m && m[1];
    }

    render() {
        super.render();

        const id = this.getItemIdFromUrl(decodeURIComponent(location.href));
        if (!id) {
            return;
        }

        const el = this.getItemElement({ id });
        if (el) {
            pageData().status?.forEach(({ status, content, ...props }) => {
                addStatusMessage(status, content, {
                    ...props,
                    title: Locale.getSection('components.status').lmsg(status),
                    renderTo: el.querySelector('.caption-header-wrap'),
                    renderMode: 'top',
                });
            });
            pageData({ ...pageData(), status: [] });
            this.scrollToItem(el);
        }
    }
}
