// Copyright 1999-2022. Plesk International GmbH. All rights reserved.
/* eslint-disable react/jsx-max-depth */

import { LocaleProvider } from '@plesk/ui-library';
import { Locale } from 'jsw';
import ErrorPage from 'common/components/ErrorPage';

const NotFound = () => (
    <LocaleProvider messages={Locale.getSection('controllers.error.error').messages}>
        <ErrorPage code="404" message="Page Not Found" />
    </LocaleProvider>
);

export default NotFound;
