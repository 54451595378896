// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { Text, Media, Status, Tooltip } from '@plesk/ui-library';
import PropTypes from 'prop-types';
import { Locale } from 'jsw';
import { NOTIFICATION_CATEGORIES, NotificationPropType } from '../lib/PropTypes';

const Translate = Locale.getTranslate('components.notification-center');

const getStatusIntent = ({ category, isRead }) => {
    if (isRead) {
        return 'inactive';
    }

    switch (category) {
        case NOTIFICATION_CATEGORIES.ALERT:
            return 'danger';
        case NOTIFICATION_CATEGORIES.WARNING:
            return 'warning';
        case NOTIFICATION_CATEGORIES.INFORMATION:
        case NOTIFICATION_CATEGORIES.FEEDBACK:
        case NOTIFICATION_CATEGORIES.PROMOTION:
            return 'info';
    }

    return 'inactive';
};

const getSubjectIntent = ({ category }) => {
    switch (category) {
        case NOTIFICATION_CATEGORIES.ALERT:
            return 'danger';
        case NOTIFICATION_CATEGORIES.WARNING:
            return 'warning';
        case NOTIFICATION_CATEGORIES.PROMOTION:
            return 'info';
        case NOTIFICATION_CATEGORIES.INFORMATION:
        case NOTIFICATION_CATEGORIES.FEEDBACK:
            return 'inactive';
    }
    return 'inactive';
};

const SubjectColumn = ({ notification, onSelectNotification, onUpdateNotifications }) => {
    const { id, subject, context, isRead } = notification;

    return (
        <Media
            image={(
                <Tooltip title={<Translate content={isRead ? 'markAsUnread' : 'markAsRead'} />}>
                    <Status
                        intent={getStatusIntent(notification)}
                        compact
                        onClick={() => onUpdateNotifications([id], {
                            isRead: !isRead,
                        })}
                        className="list-context-actions"
                        data-type={`notification-is-read-${isRead}`}
                    />
                </Tooltip>
            )}
        >
            <div
                onClick={() => onSelectNotification({ notification })}
                className="list-context-actions"
            >
                <Text
                    intent={getSubjectIntent(notification)}
                >
                    {context}
                </Text>
                <br />
                <Text
                    intent={getSubjectIntent(notification)}
                    bold={!isRead}
                >
                    {subject}
                </Text>
            </div>
        </Media>
    );
};

SubjectColumn.propTypes = {
    notification: NotificationPropType.isRequired,
    onSelectNotification: PropTypes.func.isRequired,
    onUpdateNotifications: PropTypes.func.isRequired,
};

export default SubjectColumn;
