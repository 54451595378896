// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

/* eslint-disable react/no-multi-comp */

import { Component, Panel as BasePanel, ListContainer, SmallButton } from 'jsw';

class UpdateAvailableNotification extends Component {
    _initComponentElement() {
        super._initComponentElement();

        const id = this._getConfigParam('type');

        const buttonNow = this._getConfigParam('nowElement');
        const buttonShow = this._getConfigParam('showElement');
        const linkLater = this._getConfigParam('laterElement');

        this._updateComponentElement((
            '<div class="msg-box msg-update"><div><div><div><div><div>' +
                '<div class="msg-content">' +
                    '<table class="update-container" cellspacing="0" width="100%">' +
                        '<tbody>' +
                            '<tr>' +
                                '<td class="update">' +
                                    `<span class="update-info">${this._getConfigParam('message', '')}</span>` +
                                '</td>' +
                                '<td class="update-links">' +
                                    `<button id="control-${id}-now" class="btn" type="submit">${buttonNow.text}</button>` +
                                    `<button id="control-${id}-show" class="btn" type="submit">${buttonShow.text}</button>` +
                                    `<a id="control-${id}-later" href="#"><span>${linkLater.text}</span></a>` +
                                '</td>' +
                            '</tr>' +
                        '</tbody>' +
                    '</table>' +
                '</div>' +
            '</div></div></div></div></div></div>'
        ));

        this._componentElement.querySelector(`#control-${id}-now`).addEventListener('click', event => {
            buttonNow.handler();
            event.preventDefault();
        });

        this._componentElement.querySelector(`#control-${id}-show`).addEventListener('click', event => {
            buttonShow.handler();
            event.preventDefault();
        });

        this._componentElement.querySelector(`#control-${id}-later`).addEventListener('click', event => {
            linkLater.handler();
            event.preventDefault();
        });
    }
}


class UpgradeAvailableNotification extends Component {
    _initComponentElement() {
        super._initComponentElement();

        const id = this._getConfigParam('type');

        const buttonShow = this._getConfigParam('showElement');
        const linkLater = this._getConfigParam('laterElement');

        this._updateComponentElement((
            '<div class="msg-box msg-update"><div><div><div><div><div>' +
                '<div class="msg-content">' +
                    '<table class="update-container" cellspacing="0" width="100%">' +
                        '<tbody>' +
                            '<tr>' +
                                '<td class="update">' +
                                    `<span class="update-info">${this._getConfigParam('message', '')}</span>` +
                                '</td>' +
                                '<td class="update-links">' +
                                    `<button id="control-${id}-show" class="btn" type="submit">${buttonShow.text}</button>` +
                                    `<a id="control-${id}-later" href="#"><span>${linkLater.text}</span></a>` +
                                '</td>' +
                            '</tr>' +
                        '</tbody>' +
                    '</table>' +
                '</div>' +
            '</div></div></div></div></div></div>'
        ));

        this._componentElement.querySelector(`#control-${id}-show`).addEventListener('click', event => {
            buttonShow.handler();
            event.preventDefault();
        });

        this._componentElement.querySelector(`#control-${id}-later`).addEventListener('click', event => {
            linkLater.handler();
            event.preventDefault();
        });
    }
}

class Panel extends BasePanel {
    _addTooltips() {
        // disable tooltip for description option
    }

    _getSubTitleHtml() {
        const descriptionText = this._getConfigParam('description');
        if (!descriptionText) {
            return '';
        }

        return (
            '<div class="description">' +
                `<div class="description-area">${descriptionText}</div>` +
            '</div>'
        );
    }
}

class PanelItemsGroup extends ListContainer {
}

class PanelItem extends SmallButton {
}

export const home = {
    UpdateAvailableNotification,
    UpgradeAvailableNotification,
    Panel,
    PanelItemsGroup,
    PanelItem,
};
