// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Heading, Alert, Media, MediaSection, Icon, Button, Translate } from '@plesk/ui-library';

const LoginMobileApplicationPromotion = ({ baseClassName, className, name, url, icon, onHide }) => (
    <Alert
        intent="info"
        className={classNames(baseClassName, className)}
    >
        <Media
            title={<Heading level={4}>{name}</Heading>}
            image={<Icon className={`${baseClassName}__icon`} src={icon || require('icons/64/plesk/plesk.png')} size="64" />}
        >
            <MediaSection>
                <Translate content="mobileApplicationMessage" params={{ name }} />
            </MediaSection>
            <MediaSection>
                <Button className={`${baseClassName}__button`} component="a" intent="primary" href={url} onClick={onHide}>
                    <Translate content="mobileApplicationInstall" />
                </Button>
                <Button className={`${baseClassName}__button`} onClick={onHide}>
                    <Translate content="mobileApplicationHide" />
                </Button>
            </MediaSection>
        </Media>
    </Alert>
);

LoginMobileApplicationPromotion.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.string,
    onHide: PropTypes.func,
    className: PropTypes.string,
    baseClassName: PropTypes.string,
};

LoginMobileApplicationPromotion.defaultProps = {
    icon: undefined,
    onHide: undefined,
    className: undefined,
    baseClassName: 'login-mobile-application-promotion',
};


export default LoginMobileApplicationPromotion;
